import { createStore } from "vuex";

export const store = createStore({
  state: {
    user: {},
    user_role: localStorage.getItem('userRole'),
    user_role_name: localStorage.getItem('userRoleName'),
    // Initialize user_permissions from localStorage if it exists, otherwise use an empty array
    user_permissions: JSON.parse(localStorage.getItem('userPermissions')) || [],
  },
  getters: {},
  mutations: {
    // Mutation to update user_permissions
    setUserPermissions(state, permissions) {
      state.user_permissions = permissions;
      // Save the updated permissions to localStorage
      localStorage.setItem('userPermissions', JSON.stringify(permissions));
    }
  },
  actions: {
    // Action to commit the mutation
    updateUserPermissions({ commit }, permissions) {
      commit('setUserPermissions', permissions);
    }
  },
});
